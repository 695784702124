html,
body {
  height: 100%;
  width: 100%;
  position: relatvive;
}

.c-full-page-loader {
  position: relative;
}
