@import '~@ipreo/cupcake/dist/ihs.min.css';

@import 'common/base';

.c-header {
  height: 48px;
}

.c-header-inverse {
  background-color: #32363b;
  border-color: rgba(255, 255, 255, 0.1);
}

.c-nav-link {
  color: #495057;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  line-height: 32px;
  font-size: 12px;
  font-weight: 400;
  padding: 0 8px;
}

.c-header-inverse .c-dropdown .c-header-item-link .c-text-sm {
  color: rgba(255, 255, 255, 0.9);
}
